<template>
  <v-container fluid class="down-top-padding">
    <BaseBreadcrumb :title="page.title" :module_name="page.name" :current="record.name"/>
    <v-row>
      <v-col>
        <base-profile
            :save_loading="loading"
            :module_name="page.name"
            :record="record"
            @change="save"
            @archive="archive"
            :user_type="page.model">
          <template v-slot:below_photo>
            <v-row>
              <v-col v-if="record.person && !record.person.user" class="text-center">
                <base-create-user
                  :person_id="record.person.id"
                  :user_type="page.model"
                  :person="record.person"
                  @refresh="load"/>
              </v-col>
            </v-row>
            <v-row>
              <v-col v-if="record.person && record.person.user" class="text-center">
                <base-notification
                  @refresh="load"
                  :user_id="record.person.user.id"
                  :recipient="record.person.name"
                  :phonenumber="record.person.mobilenumber"/>
              </v-col>
            </v-row>
            <v-row v-if="record.person && record.person.user">
              <v-col class="text-center">
                <v-btn @click="resetPassword" :loading="reset_password_loading" small>
                  {{ $store.getters.translate("reset_your_password") }}
                </v-btn>
              </v-col>
            </v-row>
            <v-row v-if="record.person && record.person.user">
              <v-col class="text-center">
                <v-btn @click="deleteUser" :loading="reset_delete_user_loading" small color="error">
                  {{ $store.getters.translate("delete_account") }}
                </v-btn>
              </v-col>
            </v-row>
          </template>
          <v-footer-panel ref="footer_panel" slot="footer" :model="page.name"/>
        </base-profile>
      </v-col>
    </v-row>
<!--    <v-row>
      <v-col>
        <base-planner-assessor :assessor_name="record.name" :exams="record.exams"/>
      </v-col>
    </v-row>-->
    <v-row>
      <v-col>
        <v-expansion-panels v-model="panel" multiple>
          <base-table-includable ref="exams_table" :page="page" :parent_module_id="record.id" relation_name="exams" @edit="editExam" @change="load"/>
          <base-table-includable ref="rooms_table" :page="page" :parent_module_id="record.id" relation_name="rooms" @edit="editRoom" @change="load"/>
          <base-table-includable ref="schemes_table" :page="page" :parent_module_id="record.id" relation_name="schemes" @edit="editScheme" @change="load"/>
          <base-table-includable ref="notes_table" :page="page" :parent_module_id="record.id" relation_name="notes" @create="createNote" @edit="editNote" @change="load"/>
        </v-expansion-panels>
      </v-col>
    </v-row>
    <edit-note modal_name="edit_note" :module_name="page.name" :model_id="record.id" ref="edit_note" @document_preview="preview" @refresh="load" :model_type="$models[page.name]"/>
    <create-note modal_name="create_note" :module_name="page.name" :model_id="record.id" ref="create_note" @refresh="load"/>
    <document-edit modal_name="document_edit" ref="document_edit"/>
  </v-container>
</template>

<script>
import BaseTableIncludable from "../../components/commonComponents/BaseTableIncludableData";
import BaseProfile from "../../components/commonComponents/BaseProfile";
import EditNote from "../notes/Edit";
import CreateNote from "../notes/Create";
import BaseBreadcrumb from "../../components/commonComponents/BaseBreadcrumb";
import DocumentEdit from "../documents/Edit";
import helpFunctions from "../../plugins/helpFunctions";
//import BasePlannerAssessor from "../../components/commonComponents/BasePlannerAssessor";

export default {
  components: {
    //BasePlannerAssessor,
    DocumentEdit,
    BaseTableIncludable,
    BaseProfile,
    EditNote,
    CreateNote,
    BaseBreadcrumb,
  },
  data() {
    return {
      page: {
        title: this.$store.getters.translate("assessors"),
        name: "assessors",
        model: "assessor",
      },
      record: {
        custom_fields: {}
      },
      loading: false,
      tab: 0,
      reset_password_loading: false,
      reset_delete_user_loading: false,
    };
  },
  created() {
    this.load();
  },
  methods: {
    load() {
      this.loading = true;
      this.$http
        .get(this.$store.getters.appUrl + "v2/" + this.page.name + "/" + this.$route.params.id)
        .then((response) => {
          this.record = response.data;
          if (this.record.custom_fields == null || Array.isArray(this.record.custom_fields) || typeof this.record.custom_fields != "object") {
            this.record.custom_fields = {};
          }
          if (this.record.exams) {
            this.record.exams = helpFunctions.getLocationsAndRooms(this.record.exams, true, true);
          }
          if (this.record.rooms) {
            this.record.rooms = helpFunctions.getLocationsAndRooms(this.record.rooms, true, false);
          }
          this.updateIncludableTables();
          this.loading = false;
        })
        .catch((error) => {
          this.$toasted.error(error);
          this.loading = false;
        });
    },
    updateIncludableTables() {
      this.$refs.exams_table.records = this.record.exams;
      this.$refs.rooms_table.records = this.record.rooms;
      this.$refs.schemes_table.records = this.record.schemes;
      this.$refs.notes_table.records = this.record.notes;
      this.$refs.exams_table.selected = [];
      this.$refs.rooms_table.selected = [];
      this.$refs.schemes_table.selected = [];
      this.$refs.notes_table.selected = [];
      this.$refs.footer_panel.record = this.record;
      this.$refs.footer_panel.load();
    },
    save() {
      if(!this.loading) {
        this.loading = true;
        this.$http
            .patch(this.$store.getters.appUrl + "v2/" + this.page.name + "/" + this.record.id, this.record)
            .then((response) => {
              this.loading = false;
              if (response.status == 200) {
                this.$toasted.success(this.$store.getters.translate("success"));
                this.load();
              } else {
                var errorMessage = response.data.message;
                var errors = Object.keys(response.data.message);
                this.$toasted.error([errorMessage[errors[0]]]);
              }
            })
            .catch((error) => {
              this.$toasted.error(error);
              this.loading = false;
            });
      }
    },
    archive() {
      this.$confirm(this.$store.getters.translate("are_you_sure")).then((res) => {
        if (res) {
          this.loading = true;
          this.$http
            .delete(this.$store.getters.appUrl + "v2/" + this.page.name + "/" + this.record.id)
            .then(() => {
              this.$router.push("/" + this.page.name);
              this.loading = false;
              this.$toasted.success(this.$store.getters.translate("success"));
            })
            .catch((error) => {
              this.$toasted.error(error);
              this.loading = false;
            });
        }
      });
    },
    resetPassword() {
      this.reset_password_loading = true;
      if (this.record.person && this.record.person.user) {
        this.$http
          .post(this.$store.getters.appUrl + "v2/resetpassword", {
            email: this.record.person.user.email,
          })
          .then(() => {
            this.reset_password_loading = false;
            this.$toasted.success(this.$store.getters.translate("password_reset_requested"));
            this.$emit("refresh");
          })
          .catch((error) => {
            this.$toasted.error(error);
            this.reset_password_loading = false;
          });
      }
      else {
        this.$toasted.error(this.$store.getters.translate("no_user"));
      }
    },
    deleteUser() {
      this.$confirm(this.$store.getters.translate("are_you_sure")).then((res) => {
            if (res) {
                this.reset_delete_user_loading = true;
                this.record.person.delete_user = true;
                this.$http
                  .patch(this.$store.getters.appUrl + "v2/people/" + this.record.person.id, this.record.person)
                  .then(() => {
                    this.reset_delete_user_loading = false;
                    this.load();
                  })
                  .catch(function (error) {
                    console.log(error);
                  });
            }
      });
    },
    editNote(item) {
      this.$refs.edit_note.openModal('edit_note');
      this.$refs.edit_note.load(item.id);
    },
    editExam(item) {
      this.$router.push("/exams/" + item.id);
    },
    editScheme(item) {
      this.$router.push("/schemes/" + item.id);
    },
    createNote() {
      this.$refs.create_note.openModal('create_note');
    },
    editRoom(item) {
      this.$router.push("/rooms/" + item.id);
    },
    preview(file) {
      this.$refs.document_edit.openModal('document_edit');
      this.$refs.document_edit.load(file);
    },
  },
  computed: {
    panel: {
      set(value) {
        let payload = {
          module_name: this.page.name,
          value: value,
        };
        this.$store.dispatch("setPanel", payload);
      },
      get() {
        return this.$store.getters.getPanel(this.page.name);
      },
    },
  },
};
</script>